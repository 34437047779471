import { memo, ReactNode } from "react";
import { Button, Nullable, TreeFileTabs, useDebounce } from "@epcnetwork/core-ui-kit";

import { ReusableFileModel } from "models";
import { Breadcrumbs, BreadcrumbsDataType, CircleGraph, Hint } from "components";
import { ItemNameComponent } from "../item-name-component";
import { AllocationSpreadTable } from "../allocation-spread-table/allocation-spread-table";
import { AllocationPresets } from "../allocation-presets/allocation-presets";
import {
  getDataForCircleGraph,
  getDateHoursDataName,
  getErrorTextAllocatedExtra,
  maxDayAllocationErrorText,
} from "../allocation-form.utils";
import { useAllocationData } from "../allocation-form.hook";
import {
  allocationSetupPages,
  breadcrumbDayName,
  breadcrumbTimeName,
  circleGraphLegendSubTitle,
  circleGraphTitle,
  maxHourAllocationErrorText,
  treeFileTabsTitle,
} from "../allocation-form.constants";
import { AllocationChart } from "../allocation-chart";
import { BackButton } from "../allocation-buttons";
import { InfoTooltip } from "./info-tooltip";
import { useDaysAllocation } from "./days-allocation.hook";
import { CalendarListSwitcher } from "./calendar-list-switcher";
import { Calendar } from "./calendar/calendar";

import "../allocation.css";
import styles from "./days-allocation.module.css";

type DaysAllocationProps = {
  children: ReactNode;
  allocationButton: ReactNode;
  backToGeneral: VoidFunction;
  onRemainingChange: (value: number) => void;
  onRemainingIndexChange: (value: number) => void;
  onSetPage: (page: number) => void;
  onSelectedIndex: (index: number) => void;
  selectedIndex: number;
  reusedJob: Nullable<ReusableFileModel>;
};

const DaysAllocation = memo<DaysAllocationProps>(
  ({
    children,
    onRemainingChange,
    onRemainingIndexChange,
    allocationButton,
    backToGeneral,
    onSetPage,
    onSelectedIndex,
    selectedIndex,
    reusedJob,
  }) => {
    const {
      dateHoursSpread,
      onSelectedValues,
      remainingValue,
      indexRemainingValue,
      onNextNextItem,
      nextNestItem,
      currentActiveItemName,
    } = useAllocationData();

    const {
      onChangePresets,
      handleChange,
      usedAmount,
      onClear,
      onApplyAllocation,
      getPresetValue,
      touched,
      maxDaysAllocationLimit,
      maxHoursAllocationLimit,
      submitCount,
      errors,
      displayCalendarView,
      onChangeDisplayCalendarView,
      handlePaste,
    } = useDaysAllocation(currentActiveItemName, reusedJob);

    const { debounce } = useDebounce();

    debounce(() => {
      onRemainingChange(remainingValue);
      onRemainingIndexChange(indexRemainingValue);
    });

    const maxAllocationError = maxHoursAllocationLimit.size
      ? maxHourAllocationErrorText
      : maxDaysAllocationLimit.size
        ? maxDayAllocationErrorText(maxDaysAllocationLimit.values().next().value)
        : "";

    const treeTabs = dateHoursSpread.map(
      ({ name, total, used, dayUnits, remaining, apiType }, index) => {
        const { colors, data, centralGraphText } = getDataForCircleGraph({ used, remaining });
        const dateHoursDataName = getDateHoursDataName(dateHoursSpread, index);

        const breadcrumbs: BreadcrumbsDataType[] = [
          {
            name: breadcrumbDayName,
            icon: <InfoTooltip />,
            active: true,
          },
          {
            name: breadcrumbTimeName,
            onClick: () => {
              if (remaining === 0 && !maxDaysAllocationLimit.size) {
                onSelectedIndex(index);
                onSetPage(allocationSetupPages.time);
              }
            },
          },
        ];

        if (dateHoursDataName) {
          breadcrumbs.push({
            name: `${dateHoursDataName}`,
            onClick: () => {
              onSelectedIndex(index + 1);
              dateHoursDataName && onNextNextItem([index + 1, dateHoursDataName]);
              onSetPage(allocationSetupPages.day);
            },
          });
        }

        const allEmailsError = ((touched.allEmails || submitCount) && errors.allEmails) || "";

        const preset = getPresetValue(index);
        const showCircleGraphTooltip = preset === "warmup-light" || preset === "warmup-standard";

        return {
          name: `#${index + 1} ${name}`,
          itemNameComponent: (
            <ItemNameComponent name={name} index={index} error={remaining !== 0} />
          ),
          description: `(${used})`,
          component: (
            <>
              {!reusedJob && (
                <AllocationPresets
                  onChange={onChangePresets}
                  onApplyAllocation={onApplyAllocation}
                  value={preset}
                />
              )}
              <div className={styles.componentWrapper}>
                <Breadcrumbs breadcrumbsData={breadcrumbs}>
                  <div className={styles.buttons}>
                    {!reusedJob && (
                      <Button appearance="transparent" onClick={onClear} btnSize="small">
                        Clear all
                      </Button>
                    )}
                    <CalendarListSwitcher
                      onChangeDisplayCalendarView={onChangeDisplayCalendarView}
                      displayCalendarView={displayCalendarView}
                    />
                  </div>
                </Breadcrumbs>
                {displayCalendarView ? (
                  <>
                    {!reusedJob && (
                      <div className={styles.statisticBar}>
                        <CircleGraph
                          total={total}
                          dataGraph={data}
                          colors={colors}
                          centralGraphValue={remaining}
                          centralGraphText={centralGraphText}
                          legendSubTitle={circleGraphLegendSubTitle}
                          title={circleGraphTitle}
                          showTooltip={showCircleGraphTooltip}
                        />
                        <AllocationChart data={dayUnits} />
                      </div>
                    )}
                    <Calendar
                      activeDays={dayUnits}
                      reusedJob={reusedJob}
                      className={styles.calendarWrapper}
                      onPaste={handlePaste}
                      onChange={handleChange(index)}
                    />
                  </>
                ) : (
                  <AllocationSpreadTable
                    allEmailsError={allEmailsError}
                    allocationUnitClassName={styles.unitWrapper}
                    detailsText={apiType}
                    units={dayUnits}
                    total={total}
                    remaining={remaining}
                    used={used}
                    onChange={handleChange(index)}
                    reusedJob={reusedJob}
                    showRemainingValue
                  />
                )}
              </div>
            </>
          ),
        };
      },
    );

    return (
      <>
        <BackButton backToGeneral={backToGeneral} />

        <TreeFileTabs
          onSelectedValues={onSelectedValues}
          treeTabs={treeTabs}
          title={`${treeFileTabsTitle} (${usedAmount})`}
          initiallyOpenedItem={[selectedIndex]}
          className="allocationWrapper"
          nextNestItem={nextNestItem}
          onNextNestItem={onNextNextItem}
          disableUnselectComponent
        >
          {allocationButton}
        </TreeFileTabs>
        {(remainingValue < 0 || maxAllocationError) && (
          <div className={styles.hintWrapper}>
            <Hint type="errorWarn">
              {maxAllocationError || getErrorTextAllocatedExtra(remainingValue)}
            </Hint>
          </div>
        )}
        {children}
      </>
    );
  },
);

export { DaysAllocation };
