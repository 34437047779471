import { memo, ClipboardEvent } from "react";
import cn from "classnames";
import { Nullable } from "@epcnetwork/core-ui-kit";

import { ReusableFileModel } from "models";
import { DayUnitType } from "../days-allocation.types";
import { getCalendarDays } from "./calendar.utils";
import { weekdays } from "./calendar.constants";
import { CalendarDay } from "./calendar-day";

import styles from "./calendar.module.css";

type CalendarProps = {
  activeDays: DayUnitType[];
  onChange: (value: number, rangeIndex: number) => void;
  className: string;
  onPaste: (event: ClipboardEvent<HTMLInputElement>, activeDayIndex: number) => void;
  reusedJob: Nullable<ReusableFileModel>;
};

const Calendar = memo<CalendarProps>(({ activeDays, onPaste, className, onChange, reusedJob }) => {
  const calendarDays = getCalendarDays(activeDays);
  const calendarClassNames = cn(styles.calendar, className);

  return (
    <div className={calendarClassNames}>
      <ul className={styles.weekdays}>
        {weekdays.map((day) => (
          <li className={styles.weekday} key={day}>
            <abbr className={styles.weekdayTitle} title={day}>
              {day.slice(0, 3)}
            </abbr>
          </li>
        ))}
      </ul>

      {calendarDays && (
        <ol className={styles.dayGrid}>
          {calendarDays.map((day, index) => (
            <CalendarDay
              key={index}
              onPaste={onPaste}
              onChange={onChange}
              {...day}
              reusedJob={reusedJob}
            />
          ))}
        </ol>
      )}
    </div>
  );
});

export { Calendar };
