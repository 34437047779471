import { Dispatch, SetStateAction, useMemo, useRef, useState } from "react";
import {
  ApiMiddleware,
  ExtractApiError,
  ExtractApiResponse,
  useDebounce,
  useDidMount,
  useDidUpdate,
  useFetch,
  UseFetchReturnType,
  useFilters,
  useWillUnmount,
  isEmpty,
} from "@epcnetwork/core-ui-kit";

import { List } from "../models";
import { searchMinLength } from "../constants/form.constants";
import {
  GeneralScrollElement,
  UIInfiniteScrollOptions,
  useUIInfiniteScroll,
} from "./use-ui-infinite-scroll";

type AsyncApiCall<Data> = ApiMiddleware<any, any, List<Data>, undefined, any, any, any>;
type InitialQueryParams = Partial<Omit<QueryParams, "query">>;

type InfiniteScrollOptions<RefType extends GeneralScrollElement, AsyncApiCallType> = Omit<
  UIInfiniteScrollOptions<RefType>,
  "onReachEndOfList"
> & {
  initialQueryParams?: InitialQueryParams;
  asyncApiCall: AsyncApiCallType;
  searchDebounce?: number;
  callOnMount?: boolean;
};

type UseInfiniteScrollReturnType<Data, AsyncApiCallType extends AsyncApiCall<Data>> = Omit<
  UseFetchReturnType<ExtractApiResponse<AsyncApiCallType>, ExtractApiError<AsyncApiCallType>>,
  "payload"
> & {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  payload: List<Data> | null;
  list: Data[];
};

type QueryParams = {
  offset: string;
  limit: string;
  query: string;
};

const DEFAULT_LIMIT = 100;
const INITIAL_OFFSET = 0;
const INITIAL_SEARCH_VALUE = "";

const getInitialQueryParams = (queryParams: InitialQueryParams): QueryParams => {
  return Object.assign(
    {
      offset: `${INITIAL_OFFSET}`,
      limit: `${DEFAULT_LIMIT}`,
      query: "",
    },
    queryParams,
  );
};

const useInfiniteScrollFetch = <
  RefType extends GeneralScrollElement,
  Data extends Record<string, unknown>,
  AsyncApiCallType extends AsyncApiCall<Data>,
>(
  options: InfiniteScrollOptions<RefType, AsyncApiCallType>,
): UseInfiniteScrollReturnType<Data, AsyncApiCallType> => {
  const {
    initialQueryParams: queryParams = {},
    searchDebounce = 300,
    callOnMount = true,
    containerRef,
    asyncApiCall,
  } = options;

  const [searchValue, setSearchValue] = useState(INITIAL_SEARCH_VALUE);
  const [list, setList] = useState<Data[]>([]);
  const [offset, setOffset] = useState(INITIAL_OFFSET);
  const searchValueChanged = useRef(false);
  const lastSearchValueRef = useRef("");
  const isUpdatingSearchQueryParamRef = useRef(false);

  const { debounce } = useDebounce(searchDebounce);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialQueryParams = useMemo(() => getInitialQueryParams(queryParams), []);

  const { updateQueryParams, setQueryParams, queryString, validateSearch } =
    useFilters<QueryParams>({
      isStateBased: false,
      initialState: initialQueryParams,
    });

  const asyncApiCallMeta = useFetch(asyncApiCall.setQueryParams(queryString), {
    dependencies: [queryString],
    callOnMount,
  });
  const { loading, payload } = asyncApiCallMeta;

  const resetQueryParams = () => {
    setQueryParams({
      offset: "",
      limit: "",
      query: "",
    });
  };

  useDidUpdate(() => {
    if (searchValueChanged.current) {
      if (!payload?.data) {
        setList([]);
      } else {
        setList(payload?.data);
      }
      searchValueChanged.current = false;
      containerRef.current?.scrollTo(0, 0);
    } else {
      if (payload?.data) setList((prev) => prev.concat(payload?.data));
    }
  }, [payload]);

  useDidUpdate(() => {
    searchValueChanged.current = true;
    debounce(() => {
      if (
        lastSearchValueRef.current === searchValue ||
        !validateSearch(searchValue, searchMinLength)
      ) {
        return;
      }

      isUpdatingSearchQueryParamRef.current = true;
      debounce(() => {
        updateQueryParams({
          query: searchValue,
          offset: `${INITIAL_OFFSET}`,
        });
        setOffset(INITIAL_OFFSET);
        isUpdatingSearchQueryParamRef.current = false;
      });
      lastSearchValueRef.current = searchValue;
    });
  }, [searchValue]);

  useDidUpdate(() => {
    if (isUpdatingSearchQueryParamRef.current) {
      return;
    }
    updateQueryParams({ offset: `${offset}` });
  }, [offset]);

  useWillUnmount(() => {
    resetQueryParams();
  });

  useDidMount(() => {
    setQueryParams(initialQueryParams);
    if (!isEmpty(initialQueryParams.query)) {
      setSearchValue(
        validateSearch(initialQueryParams.query, searchMinLength)
          ? initialQueryParams.query
          : INITIAL_SEARCH_VALUE,
      );
    }
  });

  useUIInfiniteScroll({
    containerRef,
    onReachEndOfList: () => {
      if (
        !searchValueChanged.current &&
        !loading &&
        payload?.total &&
        list.length < payload?.total
      ) {
        setOffset(list.length);
      }
    },
  });

  return {
    searchValue,
    setSearchValue,

    list,

    ...asyncApiCallMeta,
  };
};

export { useInfiniteScrollFetch };
