import { FC, useMemo, useRef, useState } from "react";
import {
  Button,
  Modal,
  Search,
  Switch,
  Table,
  TableContentRef,
  useDebounce,
} from "@epcnetwork/core-ui-kit";

import { ColumnsType } from "types/common.types";
import { AvailableFileModel } from "models";
import { useInfiniteScrollFetch } from "hooks/use-infinite-scroll-fetch";
import { CANCEL_TEXT } from "constants/notification.constants";
import { getAvailableFiles } from "api";
import { AttachFileTableRow } from "./attach-file-table-row/attach-file-table-row";

import globalStyles from "assets/styles/global.module.css";
import styles from "./attach-file.module.css";

type AttachFileModalProps = {
  onCancelClick: VoidFunction;
  onSubmitClick: (item: AvailableFileModel[]) => void;
  handleModalClose: VoidFunction;
  isModalOpen: boolean;
  initialSelectedFiles: AvailableFileModel[];
};

const tableColumns: ColumnsType = [
  { label: "", required: true },
  { label: "File Name", required: true },
  { label: "Columns" },
  { label: "Order", required: true },
  { label: "Upload Date" },
];

const AttachFileModal: FC<AttachFileModalProps> = ({
  onCancelClick,
  onSubmitClick,
  isModalOpen,
  handleModalClose,
  initialSelectedFiles,
}) => {
  const tableContentRef = useRef<TableContentRef>(null);

  const { debounce } = useDebounce(250);

  const [usedFiles, setUsedFiles] = useState(false);
  const [selectedItems, setSelectedItems] = useState<AvailableFileModel[]>(initialSelectedFiles);

  const { loading, list, searchValue, setSearchValue, refresh, error } = useInfiniteScrollFetch<
    typeof tableContentRef,
    AvailableFileModel,
    typeof getAvailableFiles
  >({
    containerRef: tableContentRef,
    asyncApiCall: getAvailableFiles,
    searchDebounce: 0,
  });

  const handleSearchChange = (value: string) => {
    debounce(() => {
      selectedItems.length && setSelectedItems([]);
      setSearchValue(value);
    });
  };

  const handleSelect = (items: AvailableFileModel[]) => {
    if (items.length > selectedItems.length) {
      const selectedIds = selectedItems.map((file) => file.id);
      const newItem = items.find((item) => !selectedIds.includes(item.id));
      newItem && setSelectedItems([...selectedItems, newItem]);
    } else {
      const selectedIds = items.map((file) => file.id);
      const newItems = selectedItems.filter((item) => selectedIds.includes(item.id));
      setSelectedItems(newItems);
    }
  };

  const handleUsedFilesClick = (_: string, isChecked: boolean) => setUsedFiles(isChecked);

  const handleCancelClick = () => onCancelClick();

  const handleSubmitClick = () => selectedItems.length && onSubmitClick(selectedItems);

  const getSelectionIndex = (item: AvailableFileModel): number => {
    return selectedItems.findIndex((file) => file.id === item.id) + 1;
  };

  const sortedList = useMemo(() => {
    return list.filter(({ is_used }) => is_used === usedFiles);
  }, [list, usedFiles]);

  return (
    <Modal
      isOpen={isModalOpen}
      setClose={handleModalClose}
      contentClassName={styles.modalContent}
      portalClassName={styles.portal}
    >
      <div className={styles.attachFileModalTitle}>Select file</div>
      <div className={styles.attachFileModalControlsWrapper}>
        <Search
          className={styles.attachFileModalSearch}
          searchValue={searchValue}
          setSearch={handleSearchChange}
        />
        <div className={styles.attachFileModalSwitchWrapper}>
          New files
          <Switch
            className={styles.attachFileModalSwitch}
            value="used-files"
            checked={usedFiles}
            onChange={handleUsedFilesClick}
            disableError
          />
          Used files
        </div>
      </div>
      <div className={`${styles.attachFileModalTableWrap} ${globalStyles.addScrollStyles}`}>
        <Table
          entityName="job-create-attach-file-modal"
          contentClassName={styles.attachFileModalTable}
          columns={tableColumns}
          list={sortedList}
          refresh={refresh}
          loading={loading}
          multiSelect={true}
          error={error?.message}
          itemIdKey="id"
          row={(item) => <AttachFileTableRow item={item} index={getSelectionIndex(item)} />}
          onSelect={handleSelect}
          selectedList={selectedItems}
          sharedTableContentRef={tableContentRef}
        />
      </div>
      <div className={styles.attachFileModalActionsWrap}>
        <Button appearance="secondary" onClick={handleCancelClick} className={styles.cancelButton}>
          {CANCEL_TEXT}
        </Button>
        <Button onClick={handleSubmitClick} disabled={!selectedItems.length}>
          Okay
        </Button>
      </div>
    </Modal>
  );
};

export { AttachFileModal };
