import { ChangeEvent, ClipboardEvent, FC, useMemo, useState } from "react";
import cn from "classnames";
import { useDidUpdate, Nullable } from "@epcnetwork/core-ui-kit";

import { ReusableFileModel } from "models";
import { getMaxEmails } from "../../../allocation-form.utils";
import { CalendarDayType } from "./calendar-day.type";

import globalStyles from "assets/styles/global.module.css";
import styles from "./calendar-day.module.css";

type CalendarDayProps = CalendarDayType & {
  onChange: (value: number, rangeIndex: number) => void;
  reusedJob: Nullable<ReusableFileModel>;
  onPaste: (event: ClipboardEvent<HTMLInputElement>, activeDayIndex: number) => void;
};

const CalendarDay: FC<CalendarDayProps> = ({
  onChange,
  onPaste,
  active,
  activeDayIndex,
  day,
  showMonth,
  value,
  month,
  hoursLength,
  reusedJob,
}) => {
  const [allocateValue, setAllocateValue] = useState(String(value));
  const monthClasses = (active: boolean) => cn(styles.month, { [styles.monthActive]: active });
  const maxDayAllocation = getMaxEmails(hoursLength);

  const ariaLabel = useMemo(() => `Allocation spread value for ${day} ${month}`, [day, month]);

  useDidUpdate(() => {
    setAllocateValue(String(value));
  }, [value]);

  const onHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setAllocateValue(value);
    onChange(Number(value), activeDayIndex);
  };

  const onInputClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (e.target instanceof HTMLInputElement) {
      e.target.select();
    }
  };

  const onInputPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    onPaste(event, activeDayIndex);
  };

  const dayInputStyles = cn(styles.monthInput, globalStyles.numberFieldAsTextField, {
    [styles.error]: active && value > maxDayAllocation,
    [styles.disabled]: reusedJob,
  });

  return (
    <li className={monthClasses(active)}>
      <div className={styles.dayHeader}>
        {showMonth && <span className={styles.monthName}>{month.slice(0, 3)}</span>}
        <span className={styles.day}>{day}</span>
      </div>
      {active && (
        <input
          type="number"
          className={dayInputStyles}
          onChange={onHandleChange}
          value={allocateValue}
          id="allocateValue"
          name="allocateValue"
          min={0}
          pattern="[0-9]"
          step="1"
          onPaste={onInputPaste}
          aria-label={ariaLabel}
          onClick={onInputClick}
          disabled={!!reusedJob}
        />
      )}
    </li>
  );
};

export { CalendarDay };
